export const SubscriptionStorageKey = "subscriptionToken";
export async function subscribe(
  pushPublicKey: string,
  swReg: ServiceWorkerRegistration
) {
  if (!("serviceWorker" in navigator)) return;

  if (!swReg) return;

  if ((await swReg.pushManager.permissionState()) !== "granted") {
    await Notification.requestPermission();
  }

  const subscription = await swReg.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlB64ToUint8Array(pushPublicKey),
  });

  const subscriptionB64 = Buffer.from(JSON.stringify(subscription)).toString(
    "base64"
  );
  localStorage.setItem(SubscriptionStorageKey, subscriptionB64);

  return subscriptionB64;
}

function urlB64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    // eslint-disable-next-line
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
