import useHttp from "@fast-ninjas/hooks.use-http";
import useAuthentication from "../authentication/useAuthentication";
import { useCallback, useEffect, useState } from "react";
import { Logger } from "../common/logger";
import { Payment } from "../admin/products/types";

export default function usePayments(apiEndpoint?: string) {
  const [payments, setPayments] = useState<any[]>([]);
  const { getAccessToken, user } = useAuthentication();
  const { post, get, patch, loading } = useHttp();

  const createAccessHeader = useCallback(
    async () => ({
      Authorization: `Bearer ${await getAccessToken()}`,
    }),
    [getAccessToken]
  );

  const fetchPayments = useCallback(
    async (searchTerm?: string) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await get(
          `${apiEndpoint}/api/payments?search=${searchTerm ?? ""}`,
          authHeader
        );

        /**We need to display payments without the service fee since it's not revenue for the seller */
        const paymentsWithoutServiceFee = result.map((p: Payment) => ({
          ...p,
          amount: Number(p.fees?.order ?? p.amount),
        }));
        setPayments(paymentsWithoutServiceFee);

        return result;
      } catch (err) {
        Logger.error("Unable to load payments", user);
      }
      return [];
    },
    [get, apiEndpoint, user, createAccessHeader]
  );

  const createPayment = useCallback(
    async (data: any) => {
      const authHeader = await createAccessHeader();
      const response = await post(
        `${apiEndpoint}/api/payments`,
        data,
        authHeader
      );
      if (!response.ok) throw response.statusText;
      const payment = await response.json();
      setPayments([...payments, payment]);
      return payment;
    },
    [createAccessHeader, post, apiEndpoint, payments, setPayments]
  );
  const updatePayment = useCallback(
    async (id: string, data: any) => {
      const authHeader = await createAccessHeader();
      const response = await patch(
        `${apiEndpoint}/api/payments/${id}/status`,
        data,
        authHeader
      );
      if (!response.ok) throw response.statusText;
      const payment = await response.json();
      const existing = payments.filter((p) => p.id !== payment.id);
      setPayments([...existing, payment]);
      return payment;
    },
    [createAccessHeader, patch, apiEndpoint, payments, setPayments]
  );

  const confirmOTP = useCallback(
    async (otp: string, paymentId: string, externalPaymentId: string) => {
      const authHeader = await createAccessHeader();
      const response = await post(
        `${apiEndpoint}/api/payments/${paymentId}/confirm-otp`,
        { otp, externalPaymentId },
        authHeader
      );
      if (!response.ok) throw response.statusText;
      return true;
    },
    [createAccessHeader, post, apiEndpoint]
  );
  useEffect(() => {
    fetchPayments()
      .then((ps) => setPayments(ps))
      .catch(Logger.error);
  }, [fetchPayments]);

  return {
    payments,
    processing: loading,
    createPayment,
    updatePayment,
    fetchPayments,
    confirmOTP,
  };
}
