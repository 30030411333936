import useHttp from "@fast-ninjas/hooks.use-http";
import { useCallback, useEffect, useState } from "react";
import useAuthentication from "../../authentication/useAuthentication";
import config from "../../configLoader";

type AnalyticModel = {
  approvedAmount: number;
  failedAmount: number;
  failedCount: number;
  numOrders: number;
  offlineAmount: number;
  offlineCount: number;
  totalAmount: number;
  totalCount: number;
  totalSales: number;
};

const NullAnalytic: AnalyticModel = {
  approvedAmount: 0,
  failedAmount: 0,
  failedCount: 0,
  numOrders: 0,
  offlineAmount: 0,
  offlineCount: 0,
  totalAmount: 0,
  totalCount: 0,
  totalSales: 0,
};

export function useAnalytics() {
  const [analytics, setAnalytics] = useState<AnalyticModel | null>(null);
  const { get, loading } = useHttp();
  const { getAccessToken } = useAuthentication();
  const createAccessHeader = useCallback(
    async () => ({
      Authorization: `Bearer ${await getAccessToken()}`,
    }),
    [getAccessToken]
  );

  const fetchReport = useCallback(
    async (period: string, userId?: string) => {
      const authHeader = await createAccessHeader();
      let url = `${config.apiEndpoint}/api/analytics/${period}`;
      if (userId) {
        url = `${url}?user=${userId}`;
      }

      const statsData: AnalyticModel[] = await get(url, authHeader, 300);

      const aggregate: AnalyticModel = statsData.reduce((p, c) => {
        return {
          approvedAmount: Number(c.approvedAmount) + (p.approvedAmount ?? 0),
          failedAmount: Number(c.failedAmount) + (p.failedAmount ?? 0),
          failedCount: Number(c.failedCount) + (p.failedCount ?? 0),
          numOrders: Number(c.numOrders) + (p.numOrders ?? 0),
          offlineAmount: Number(c.offlineAmount) + (p.offlineAmount ?? 0),
          offlineCount: Number(c.offlineCount) + (p.offlineCount ?? 0),
          totalAmount: Number(c.totalAmount) + (p.totalAmount ?? 0),
          totalCount: Number(c.totalCount) + (p.totalCount ?? 0),
        };
      }, NullAnalytic as any);

      aggregate.totalSales = aggregate.totalAmount - aggregate.failedAmount;

      setAnalytics(aggregate);
      return aggregate;
    },
    [get, createAccessHeader]
  );

  useEffect(() => {
    fetchReport("today");
    // eslint-disable-next-line
  }, []);

  return { analytics, fetchReport, loading };
}
