export function socialDate(date: string) {
  const theDate = new Date(date);
  const now = new Date();
  const diff = now.getTime() - theDate.getTime();
  const days = Math.floor(diff / (1000 * 3600 * 24));
  if (days < 1) {
    return `Today ${theDate.toLocaleTimeString()}`;
  }
  if (days < 2) {
    return `Yesterday ${theDate.toLocaleTimeString()}`;
  }
  return `${days} days ago`;
}
