import { useCallback, useState } from "react";
import { UserRoles } from "../../authentication/userRoles";
import config from "../../configLoader";
import { useProducts } from "./useProducts";
import AdminLayout, { FormContainer } from "../layout";
import useS3Upload from "../../hooks/useS3Upload";
import useAuthentication from "../../authentication/useAuthentication";
import { Logger } from "../../common/logger";
import { ProductForm } from "./ProductForm";
import { FormStates } from "../../common/constants";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";

export default function CreateProductPage() {
  const [formState, setFormState] = useState(FormStates.Initial);
  const { user } = useAuthentication();
  const { createProduct, processing } = useProducts(config.apiEndpoint);
  const { uploadFile } = useS3Upload(`${config.apiEndpoint}/api/files`);

  const formSubmitHandler = useCallback(
    async (data: any) => {
      try {
        const { fileKey } = await uploadFile(
          data.files[0],
          `product/${user?.attributes["custom:accountId"]}`
        );
        await createProduct({
          name: data.productName,
          price: data.price,
          imageUrl: fileKey,
          options: data.options ?? [],
        });
        setFormState(FormStates.Success);
      } catch (error) {
        Logger.error(error);
        setFormState(FormStates.Error);
      }
    },
    [uploadFile, createProduct, user?.attributes]
  );

  return (
    <AdminLayout allowRoles={[UserRoles.ManageProducts, UserRoles.ClientAdmin]}>
      <FormContainer>
        {formState === FormStates.Success && (
          <Alert severity="success">
            Product added successfully! <Link to="/products">Go back</Link>
          </Alert>
        )}
        {formState === FormStates.Error && (
          <Alert severity="error">Update failed. Try again later</Alert>
        )}
        <ProductForm onSubmit={formSubmitHandler} loading={processing} />
      </FormContainer>
    </AdminLayout>
  );
}
