import useHttp from "@fast-ninjas/hooks.use-http";
import useAuthentication from "../authentication/useAuthentication";
import { useCallback, useEffect, useState } from "react";
import { Logger } from "../common/logger";

export default function useContacts(apiEndpoint?: string) {
  const [next, setNext] = useState<string | null>(null);
  const [contacts, setContacts] = useState<any[]>([]);
  const { getAccessToken, user } = useAuthentication();
  const { get, loading } = useHttp();

  const createAccessHeader = useCallback(
    async () => ({
      Authorization: `Bearer ${await getAccessToken()}`,
    }),
    [getAccessToken]
  );

  const fetchContacts = useCallback(
    async (nextToken?: string, append: boolean = false) => {
      const authHeader = await createAccessHeader();
      try {
        const result = await get(
          `${apiEndpoint}/api/contacts?next=${nextToken ?? ""}`,
          authHeader
        );
        if (!append) {
          setContacts(result.contacts);
        } else {
          setContacts([...contacts, ...result.contacts]);
        }
        if (result.next) {
          setNext(result.next);
        }
        return result;
      } catch (err) {
        Logger.error("Unable to load contacts", user);
      }
      return [];
    },
    [apiEndpoint, user, contacts, get, createAccessHeader]
  );

  useEffect(() => {
    fetchContacts().catch(Logger.error);
    // Should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    contacts,
    processing: loading,
    fetchContacts,
    canLoadMore: Boolean(next),
    loadMore: async () => {
      if (next) {
        await fetchContacts(next, true).catch(Logger.error);
      }
    },
  };
}
