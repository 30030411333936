import { useCallback, useEffect, useMemo, useState } from "react";
import { Product } from "../admin/products/types";
import { Logger } from "../common/logger";

const storageKey = "order";

export type CartItem = {
  key: string;
  quantity: number;
  item: {
    id?: string;
    name: string;
    imageFile: string;
    price: number;
    selection?: { name: string; price: number; quantity: number }[];
  };
};

function calculateCost(item: CartItem) {
  const selections = item.item.selection ?? [];
  const unitCost =
    Number(item.item.price) +
    selections.reduce((sum, { quantity, price }) => {
      return sum + Number(quantity) * Number(price);
    }, 0);

  console.log(
    "Unit: ",
    unitCost,
    " Quantity: ",
    item.quantity,
    " Total: ",
    (unitCost * item.quantity).toFixed(2)
  );
  return (unitCost * item.quantity).toFixed(2);
}

export default function useCart() {
  const [items, setItems] = useState<CartItem[]>([]);

  const addItem = useCallback(
    (product: Product, quantity: number) => {
      const newItem = {
        key: `${new Date().getTime()}.${product.id}`,
        quantity,
        item: {
          id: product.id,
          name: product.name,
          imageFile: product.imageUrl,
          price: product.price,
          selection:
            product.options?.map((o) => ({
              name: o.name,
              price: o.price,
              quantity: 0,
            })) || undefined,
        },
      };
      const newItems: CartItem[] = [...items, newItem];

      setItems(newItems);
      persist(newItems);
      return newItem;
    },
    [setItems, items]
  );

  const removeItem = useCallback(
    (key: string) => {
      const newItems = items.filter((i) => i.key !== key);
      setItems(newItems);
      persist(newItems);
    },
    [items, setItems]
  );

  const emptyCart = useCallback(() => {
    const newItems: CartItem[] = [];
    setItems(newItems);
    persist(newItems);
  }, [setItems]);

  const updateItem = useCallback(
    (item: CartItem) => {
      const oldItem = items.find((i) => i.key === item.key) || {};

      const newItems = items.filter((i) => i.key !== item.key);
      newItems.push({ ...oldItem, ...item });

      setItems(newItems);
      persist(newItems);
    },
    [items, setItems]
  );

  const cost = useMemo(() => {
    return items
      .reduce((sum, item) => {
        return sum + Number(calculateCost(item));
      }, 0)
      .toFixed(2);
  }, [items]);

  useEffect(() => {
    const savedOrderJson = localStorage.getItem(storageKey);
    if (!savedOrderJson) return;
    try {
      const order = JSON.parse(savedOrderJson);
      setItems(order);
    } catch (err) {
      Logger.error(err);
    }
  }, [setItems]);

  return {
    items,
    cost,
    addItem,
    updateItem,
    removeItem,
    emptyCart,
    calculateCost,
  };
}

function persist(order: CartItem[]) {
  localStorage.setItem(storageKey, JSON.stringify(order));
}
