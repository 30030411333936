import useHttp from "@fast-ninjas/hooks.use-http";
import { useCallback } from "react";
import useAuthentication from "../authentication/useAuthentication";

export default function useS3Upload(uploadUrl: string) {
  const { put, get, loading } = useHttp();
  const { getAccessToken } = useAuthentication();

  type FileUploadResult = {
    fileKey: string;
  };

  const createAccessHeader = useCallback(
    async () => ({
      Authorization: `Bearer ${await getAccessToken()}`,
    }),
    [getAccessToken]
  );

  const uploadFile = useCallback(
    async function (file: File, folderName: string): Promise<FileUploadResult> {
      const fileName = `${folderName}/${file.name}`;
      const authHeader = await createAccessHeader();

      const signingResp = await put(
        `${uploadUrl}?fileName=${fileName}`,
        {},
        authHeader
      );

      if (!signingResp.ok) throw Error("Unable to upload file. Signing failed");

      const { signedUrl, fileKey }: any = await signingResp.json();
      const response = await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      if (response.ok) return { fileKey };
      else throw new Error("UploadFailed");
    },
    [uploadUrl, put, createAccessHeader]
  );

  const loadImage = useCallback(
    async (imgName: string) => {
      const authHeader = await createAccessHeader();
      const oneDay = 60 * 60 * 24; // in seconds
      const { signedUrl } = await get(
        `${uploadUrl}?fileName=${imgName}`,
        authHeader,
        oneDay
      );
      return signedUrl;
    },
    [uploadUrl, get, createAccessHeader]
  );

  return { uploadFile, loadImage, loading };
}
