import {
  Alert,
  Box,
  Button,
  Snackbar,
  Typography,
  styled,
} from "@mui/material";
import theme from "./theme";
import appConfig from "./appConfig.json";
import "./Layout.css";
import config from "./configLoader";
import { useSwUpdate } from "./hooks/useSwUpdate";
type AppLayoutProps = {
  children: JSX.Element | JSX.Element[];
  menuComponent?: JSX.Element;
  bgClass?: string;
};

const PageContainer = styled(Box)`
  background-color: ${() => theme.palette.background.default};
  margin: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.9;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
`;

const ContentPane = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "85vh",
}));

const HeaderSection = styled(Box)`
  width: 100%;
  height: 8vh;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FooterSection = styled(Box)`
  width: 100%;
  height: 10vh;
  text-align: center;
`;

export default function AppLayout(props: AppLayoutProps) {
  const { reloadPage, showReload, waitingWorker } = useSwUpdate();

  const { children, menuComponent, bgClass } = props;
  const { appName } = appConfig;
  return (
    <PageContainer className={bgClass}>
      <HeaderSection>
        <Typography variant="h6">{appName}</Typography>
        {menuComponent}
      </HeaderSection>
      <ContentPane>
        {waitingWorker && showReload && (
          <Snackbar
            open={true}
            anchorOrigin={{ horizontal: "left", vertical: "top" }}
          >
            <Alert
              severity="info"
              action={<Button onClick={reloadPage}>Update</Button>}
            >
              A new version is available.
            </Alert>
          </Snackbar>
        )}
        {children}
      </ContentPane>
      <FooterSection>
        <Typography>All rights reserved. {new Date().getFullYear()}</Typography>
        <Typography color="gray" fontSize="12px">
          powered by TD Technologies Ltd. V: {config.timestamp}
        </Typography>
      </FooterSection>
    </PageContainer>
  );
}
