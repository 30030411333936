import { Box, Card, CircularProgress, styled } from "@mui/material";
import useAuthentication from "../authentication/useAuthentication";
import { CenterContent } from "../components/centerContent";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import AppLayout from "../layout";
import MenuBar, { MenuItemProps } from "./menuBar";
import {
  AnalyticsOutlined,
  MonetizationOn,
  PeopleAltOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import { UserRoles } from "../authentication/userRoles";

type AdminLayoutProps = {
  children: JSX.Element | JSX.Element[];
  allowRoles?: string[];
  redirectTo?: string;
};

export default function AdminLayout(props: AdminLayoutProps) {
  const navigateTo = useNavigate();
  const { loading, user, signOut, hasRole } = useAuthentication();

  const { children, allowRoles, redirectTo } = props;

  useEffect(() => {
    if (!user && !loading) {
      navigateTo("/");
    }

    if (
      !loading &&
      allowRoles &&
      !allowRoles.some((role: string) => {
        return hasRole(role);
      })
    ) {
      navigateTo(redirectTo ?? "/dashboard");
    }
  }, [user, loading, navigateTo, allowRoles, redirectTo, hasRole]);

  const MenuComponent = useMemo(() => {
    const userMenuItems = [
      {
        text: "Reporting",
        onClick: () => navigateTo("/analytics"),
        icon: <AnalyticsOutlined fontSize="small" color="info" />,
      },
      {
        text: "Check for update",
        onClick: () => window.location.reload(),
        icon: <RefreshOutlined fontSize="small" color="warning" />,
      },
      { text: "Sign out", onClick: signOut },
    ];
    const otherMenuItems: MenuItemProps[] = [
      // { text: "Home", onClick: () => navigateTo("/dashboard"), icon: <Home /> },
      {
        text: "Home",
        onClick: () => navigateTo("/orders"),
        icon: <MonetizationOn />,
      },
      {
        text: "Contacts",
        onClick: () => navigateTo("/contacts"),
        icon: <PeopleAltOutlined />,
      },
    ];
    if (hasRole(UserRoles.ManageProducts) || hasRole(UserRoles.ClientAdmin)) {
      otherMenuItems.push({
        text: "Products",
        onClick: async () => navigateTo("/products"),
      });
    }

    return (
      <MenuBar
        username={user?.attributes?.given_name}
        userMenu={userMenuItems}
        menuItems={otherMenuItems}
      />
    );
  }, [signOut, user?.attributes?.given_name, navigateTo, hasRole]);

  if (loading) {
    return (
      <AppLayout>
        <CenterContent>
          <CircularProgress />
        </CenterContent>
      </AppLayout>
    );
  }

  return (
    <AppLayout menuComponent={MenuComponent}>
      <Box p={3} width="100%" minHeight="85vh">
        {children}
      </Box>
    </AppLayout>
  );
}

export const FormContainer = styled(Card)`
  padding: 24px;
`;
