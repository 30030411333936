import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import AdminLayout from "../layout";
import { useAnalytics } from "./useAnalytics";
import { MenuButton } from "../../components/button";
import { ArrowDropDown } from "@mui/icons-material";
import { useCallback, useState } from "react";
import { CenterContent } from "../../components/centerContent";
import theme from "../../theme";
import Spacing from "../../components/space";

const ReportPeriods = [
  { text: "Today", value: "today" },
  { text: "Yesterday", value: "yd" },
  { text: "This Week", value: "wtd" },
  { text: "Last Week", value: "lw" },
  { text: "This Month", value: "mtd" },
  { text: "Last Month", value: "lm" },
  { text: "This Quarter", value: "qtd" },
  { text: "Last Quarter", value: "lq" },
  { text: "This Year", value: "ytd" },
  { text: "Last Year", value: "ly" },
];

const ListContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: "#ededed",
  padding: "16px",
  width: "90%",
  [theme.breakpoints.up("md")]: {
    width: "30%",
  },
  alignItems: "center",
  overflowX: "scroll",
  height: "100%",
}));

export function AnalyticsPage() {
  const { analytics, loading, fetchReport } = useAnalytics();

  const datePeriodChangeHandler = useCallback(
    async (period: Period) => {
      console.log("New Period: ", period.value);
      try {
        await fetchReport(period.value);
      } catch {
        alert("There was a problem loading the data");
      }
    },
    [fetchReport]
  );

  console.log("Current: ", JSON.stringify(analytics));
  return (
    <AdminLayout>
      <Stack width="100%" alignItems="center" height="100%">
        <ListContainer>
          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Typography variant="h5" alignSelf="start">
              Stats
            </Typography>
            <PeriodSelector
              periods={ReportPeriods}
              onChange={datePeriodChangeHandler}
            />
          </Stack>
          {loading && (
            <CenterContent>
              <CircularProgress />
            </CenterContent>
          )}
          {!loading && analytics && (
            <>
              <Spacing />
              <StatsCard
                title="Total Sales"
                value={analytics.totalSales.toFixed(2)}
                prefix="GHS"
                color={theme.palette.primary.main}
              />
              <StatsCard
                title="Electronic"
                value={analytics.approvedAmount.toFixed(2)}
                prefix="GHS"
                color="grey"
              />
              <StatsCard
                title="Cash"
                value={analytics.offlineAmount.toFixed(2)}
                prefix="GHS"
                color="grey"
              />
              <StatsCard
                title="# of Orders"
                value={analytics.numOrders.toString()}
                color="grey"
              />
            </>
          )}
          <Spacing size={100} />
        </ListContainer>
      </Stack>
    </AdminLayout>
  );
}

type Period = { text: string; value: string };
type PeriodSelectorProps = {
  periods: Period[];
  onChange?: (period: Period) => void;
};

function PeriodSelector(props: PeriodSelectorProps) {
  const { periods, onChange } = props;

  const [period, setPeriod] = useState<Period>();

  const handleChange = (newPeriod: Period) => {
    setPeriod(newPeriod);
    onChange && period && onChange(newPeriod);
  };

  const menuOptions = periods?.map((p) => ({
    text: p.text,
    onClick: () => handleChange(p),
  }));

  return (
    <Box>
      <MenuButton menuItems={menuOptions} icon={<ArrowDropDown />}>
        {period?.text ?? ""}
      </MenuButton>
    </Box>
  );
}

function StatsCard({
  title,
  value,
  prefix,
  fontSize,
  color,
}: {
  title: string;
  value: string;
  prefix?: string;
  fontSize?: number;
  color?: string;
}) {
  return (
    <Card style={{ width: "75%", margin: 4 }} elevation={0}>
      <CardContent>
        <Typography color="gray">{title}</Typography>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <Typography fontWeight={50} fontSize={9}>
            {prefix}
          </Typography>
          <Typography fontWeight={900} fontSize={fontSize ?? 24} color={color}>
            {value}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
}
