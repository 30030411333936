import {
  Alert,
  Box,
  InputAdornment,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import ProgressButton from "../../components/button";
import { useCallback, useMemo, useState } from "react";
import Spacing from "../../components/space";
import { Validator } from "../../common/validation";
import { PhoneAndroidOutlined } from "@mui/icons-material";

export type NewPaymentFormProps = {
  loading?: boolean;
  amount?: string;
  hideAmount?: boolean;
  hideHeader?: boolean;
  disabled?: boolean;
  onSubmit?: (data: any) => void;
};

export function NewPaymentForm(props: NewPaymentFormProps) {
  const {
    amount: initialAmount,
    hideAmount,
    hideHeader,
    loading,
    disabled,
    onSubmit,
  } = props;
  const [amount, setAmount] = useState(initialAmount ?? "");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [provider, setProvider] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const validate = useCallback(() => {
    let error = "";
    setErrorMessage(error);
    if (!Validator.numberBetween(amount, 5000)) {
      error = "Invalid amount";
    } else if (!Validator.isValidPhoneNumber(phoneNumber)) {
      error = "Invalid phone number";
    } else if (!provider) {
      error = "Select payment method";
    }
    return error;
  }, [amount, phoneNumber, provider]);

  const hasErrors = useMemo(() => validate(), [validate]);

  const requestPaymentClickHandler = useCallback(() => {
    const error = validate();
    if (error) {
      setErrorMessage(error);
      return;
    }

    if (onSubmit) {
      onSubmit({ amount, phoneNumber, provider });
    }
  }, [amount, phoneNumber, provider, validate, onSubmit]);

  return (
    <Box>
      {hideHeader ? null : <Typography variant="h5">New Payment</Typography>}
      {errorMessage && (
        <Alert severity="error" color="error">
          {errorMessage}
        </Alert>
      )}
      {hideAmount ? null : (
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="amount"
          label={amount ? "Amount" : undefined}
          name="amount"
          autoComplete="off"
          autoFocus
          value={amount}
          size="medium"
          type="number"
          inputMode="decimal"
          placeholder="0.00"
          disabled={loading || disabled}
          onChange={(e) => setAmount(e.currentTarget.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography fontSize={10}>GHS</Typography>
              </InputAdornment>
            ),
          }}
        />
      )}
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="phoneNumber"
        label={phoneNumber ? "Phone Number" : undefined}
        name="phoneNumber"
        autoComplete="off"
        value={phoneNumber}
        size="medium"
        inputMode="tel"
        type="tel"
        placeholder="Phone number"
        disabled={loading || disabled}
        onChange={(e) => setPhoneNumber(e.currentTarget.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneAndroidOutlined />
            </InputAdornment>
          ),
        }}
      />
      <Spacing />
      <Stack direction="row" justifyContent="center">
        <ToggleButtonGroup
          color="primary"
          value={provider}
          exclusive
          onChange={(_, v) => setProvider(v)}
          aria-label="Platform"
          disabled={loading || disabled}
        >
          <ToggleButton value="mtn">MTN MoMo</ToggleButton>
          <ToggleButton value="voda">Voda Cash</ToggleButton>
          <ToggleButton value="airtel">Airtel-Tigo</ToggleButton>
          <ToggleButton value="cash">Offline (Cash)</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Spacing size={24} />
      <ProgressButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={requestPaymentClickHandler}
        disabled={hasErrors?.length > 0 || loading || disabled}
        isBusy={loading}
        size="large"
      >
        Place Order
      </ProgressButton>
    </Box>
  );
}
