import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import useCart, { CartItem } from "../../hooks/useCart";
import { S3Image } from "../../components/s3Image";
import Currency from "../../components/currency";
import Spacing from "../../components/space";
import { QuantityEditor } from "./QuantityEditor";
import { useCallback, useMemo, useState } from "react";

type CustomizeOrderProps = {
  item: CartItem;
  isOpen?: boolean;
  onClose?: () => void;
  onChange?: (item: CartItem) => void;
};

export default function CustomizeOrder(props: CustomizeOrderProps) {
  const { item, isOpen, onClose, onChange } = props;
  const [orderItem, setOrderItem] = useState(item);
  const { calculateCost } = useCart();

  const optionUpdated = useCallback(
    (quantity: number, idx: number) => {
      const itemCopy = { ...orderItem };
      if (!itemCopy.item.selection) return;
      itemCopy.item.selection[idx].quantity = quantity;
      setOrderItem(itemCopy);
    },
    [orderItem, setOrderItem]
  );

  const onChangedHandler = useCallback(() => {
    onChange && onChange(orderItem);
  }, [orderItem, onChange]);

  const itemCost = useMemo(
    () => calculateCost(orderItem),
    [orderItem, calculateCost]
  );

  return (
    <Dialog onClose={onClose} open={isOpen ?? false} fullWidth>
      <DialogTitle>
        <Typography>Order: {item.item.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack alignItems="center">
          <S3Image
            fileName={item.item.imageFile}
            style={{ maxWidth: 120, maxHeight: 120 }}
          />
          <Spacing />

          <Box width="70%">
            {item.item.selection?.map((opt, idx) => (
              <Stack
                direction="row"
                key={`${opt.name}-${idx}`}
                width="100%"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">{opt.name}</Typography>
                <QuantityEditor
                  value={opt.quantity}
                  minimum={0}
                  maximum={25}
                  onChange={(v) => optionUpdated(v, idx)}
                />
              </Stack>
            ))}
          </Box>
          <Spacing />
          <Currency amount={Number(itemCost)} variant="large" />
          <Spacing />

          <Stack direction="row" justifyContent="space-between" width="80%">
            <Button variant="contained" onClick={onChangedHandler}>
              Save To Cart
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
