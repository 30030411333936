import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import config from "../../configLoader";
import { useProducts } from "./useProducts";
import AdminLayout from "../layout";
import Spacing from "../../components/space";
import { Column, DataTable } from "@fast-ninjas/admin.data-table";
import { UserRoles } from "../../authentication/userRoles";
import { useNavigate } from "react-router-dom";
import { CenterContent } from "../../components/centerContent";
import { S3Image } from "../../components/s3Image";
type ProductsPageProps = {};

const productImageStyle = {
  maxWidth: "60px",
  maxHeight: "60px",
};

const tableColumns: Column[] = [
  {
    field: "name",
    title: "Name",
  },
  {
    field: "price",
    title: "Price (GHS)",
    align: "right",
  },
  {
    field: "imageUrl",
    title: "Image",
    render: function (imageUrl: string) {
      if (!imageUrl) return "";
      return <S3Image fileName={imageUrl} style={productImageStyle} />;
    },
  },
];

const ListContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: "#ededed",
  padding: "16px",
  width: "90%",
  [theme.breakpoints.up("md")]: {
    width: "40%",
  },
  alignItems: "center",
  overflowX: "scroll",
  height: "100%",
}));

export function ProductsPage(props: ProductsPageProps) {
  const { products, processing } = useProducts(config.apiEndpoint);
  const navigateTo = useNavigate();

  return (
    <AdminLayout allowRoles={[UserRoles.ClientAdmin, UserRoles.ManageProducts]}>
      <Stack width="100%" alignItems="center" height="100%">
        <Button variant="outlined" onClick={() => navigateTo("/products/new")}>
          Add New
        </Button>
        <Spacing />
        <ListContainer>
          <Typography variant="h5" alignSelf="start">
            Products/Services
          </Typography>
          <Spacing />
          {processing ? (
            <CenterContent>
              <CircularProgress />
            </CenterContent>
          ) : (
            <DataTable
              columns={tableColumns}
              data={products}
              onRowClicked={(product) =>
                navigateTo(`/products/${product.id}/manage`, { state: product })
              }
            />
          )}
        </ListContainer>
      </Stack>
    </AdminLayout>
  );
}
