import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import config from "../../configLoader";
import useS3Upload from "../../hooks/useS3Upload";
import { useCallback, useEffect, useState } from "react";
import { Add } from "@mui/icons-material";
import { Product } from "../products/types";

export type ProductDisplayProps = {
  product: Product;
  addItem: (product: Product, quantity: number) => void;
};

const cardStyle = { margin: 8, width: 260, minHeight: 200, marginBottom: 24 };

export function ProductDisplay(props: ProductDisplayProps) {
  const { product, addItem } = props;
  const { loadImage } = useS3Upload(`${config.apiEndpoint}/api/files`);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    loadImage(product.imageUrl).then(setImageUrl);
  }, [setImageUrl, product.imageUrl, loadImage]);

  const addToOrderTray = useCallback(
    () => addItem(product, 1),
    [product, addItem]
  );

  return (
    <Card style={cardStyle}>
      <CardHeader title={product.name} component="h5" />
      {imageUrl && <CardMedia component="img" image={imageUrl} />}
      <CardContent>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography color="grey">GHS</Typography>
          <Typography variant="h5">{product.price}</Typography>
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          variant="outlined"
          fullWidth
          size="large"
          onClick={addToOrderTray}
        >
          Add to order <Add />
        </Button>
      </CardActions>
    </Card>
  );
}
