import { useCallback, useState } from "react";
import { EnterOTPForm } from "../../components/otpForm";

type PaymentOtpFormProps = {
  processing?: boolean;
  onSendOtp?: (otp: string) => void;
  onResendOtp?: () => void;
};

export default function PaymentOtpForm(props: PaymentOtpFormProps) {
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { processing, onSendOtp, onResendOtp } = props;

  const sendOtpHandler = useCallback(() => {
    if (!otp) {
      setErrorMessage("OTP required to continue");
      return;
    }

    if (onSendOtp) {
      onSendOtp(otp);
    }
  }, [otp, onSendOtp]);

  return (
    <EnterOTPForm
      onSubmit={sendOtpHandler}
      loading={processing}
      headerText=""
      errorMessage={errorMessage}
      setOTP={setOtp}
      resendOtp={onResendOtp}
      otp={otp}
      placeholder="Enter OTP from customer"
    />
  );
}
