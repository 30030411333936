import { ShoppingBag } from "@mui/icons-material";
import {
  Box,
  Typography,
  Alert,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useState, useCallback, useMemo, useEffect } from "react";
import { Validator } from "../../common/validation";
import ProgressButton from "../../components/button";
import Spacing from "../../components/space";
import config from "../../configLoader";
import { ImageField } from "./ImageField";
import useS3Upload from "../../hooks/useS3Upload";
import { Product, ProductOption } from "./types";
import SimpleTabs from "../../components/tab";
import ProductOptionsEditor from "./optionsEditor";

export type ProductFormProps = {
  loading?: boolean;
  onSubmit?: (data: any) => void;
  product?: Product;
};

export function ProductForm(props: ProductFormProps) {
  const { loading, onSubmit, product } = props;
  const { loadImage } = useS3Upload(`${config.apiEndpoint}/api/files`);
  const [price, setPrice] = useState(product?.price?.toString() ?? "");
  const [productName, setProductName] = useState(product?.name ?? "");
  const [files, setFiles] = useState<File[]>([]);
  const [imageUrl, setImageUrl] = useState("");
  const [options, setOptions] = useState<ProductOption[]>(
    product?.options ?? []
  );
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    product?.imageUrl && loadImage(product?.imageUrl).then(setImageUrl);
  }, [product?.imageUrl, loadImage, setImageUrl]);

  const validate = useCallback(() => {
    let error = "";
    setErrorMessage(error);
    if (!Validator.numberBetween(price, 50000)) {
      error = "Invalid price";
    } else if (Validator.isNullOrBlank(productName)) {
      error = "Product name is required";
    } else if (!product?.imageUrl && !files?.length) {
      error = "Choose a product image";
    }
    return error;
  }, [price, productName, files, product?.imageUrl, setErrorMessage]);

  const hasErrors = useMemo(() => validate(), [validate]);

  const createButtonClickHandler = useCallback(() => {
    const error = validate();
    if (error) {
      setErrorMessage(error);
      return;
    }

    if (onSubmit) {
      onSubmit({ price, productName, files, options });
    }
  }, [price, productName, files, validate, onSubmit, options, setErrorMessage]);

  const optionsChangedHandler = useCallback(
    (newOptions: ProductOption[]) => {
      setOptions(newOptions);
    },
    [setOptions]
  );

  const tabItems = useMemo(() => {
    return [
      {
        title: "Product Image",
        content: (
          <ImageField
            label="Product image"
            max={2}
            onFileChange={(files) => setFiles(files)}
            fileUrls={[imageUrl]}
          />
        ),
      },
      {
        title: "Options",
        content: (
          <ProductOptionsEditor
            options={options}
            onChange={optionsChangedHandler}
          />
        ),
      },
    ];
  }, [imageUrl, setFiles, options, optionsChangedHandler]);

  return (
    <Box>
      <Typography variant="h5">{product ? "Manage" : "Product"}</Typography>
      {errorMessage && (
        <Alert severity="error" color="error">
          {errorMessage}
        </Alert>
      )}
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="price"
        label={price ? "price" : undefined}
        name="price"
        autoComplete="off"
        autoFocus
        value={price}
        size="medium"
        type="number"
        inputMode="decimal"
        placeholder="0.00"
        disabled={loading}
        onChange={(e) => setPrice(e.currentTarget.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography fontSize={10}>GHS</Typography>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="productName"
        label={productName ? "Product/Service" : undefined}
        name="productName"
        autoComplete="off"
        value={productName}
        size="medium"
        placeholder="Product/Service"
        disabled={loading}
        onChange={(e) => setProductName(e.currentTarget.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ShoppingBag />
            </InputAdornment>
          ),
        }}
      />
      <Spacing />
      <SimpleTabs items={tabItems} />
      <Spacing size={24} />
      <ProgressButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={createButtonClickHandler}
        disabled={hasErrors?.length > 0 || loading}
        isBusy={loading}
        size="large"
      >
        {product ? "Save Changes" : "Create"}
      </ProgressButton>
    </Box>
  );
}
