import { FormContainer } from "../layout";
import { useCallback, useState } from "react";
import { NewPaymentForm } from "./newPaymentForm";
import PaymentOtpForm from "./paymentOtpForm";
import { StatusCard } from "../../components/statusCard";
import usePayments from "../../hooks/usePayments";
import config from "../../configLoader";
import { Logger } from "../../common/logger";
import { useNavigate } from "react-router-dom";
import { CartItem } from "../../hooks/useCart";

const SendOTPStatus = "Awaiting OTP";
enum FormStates {
  InitialPayment,
  ConfirmOtp,
  Success,
  Error,
}

type PaymentWidgetProps = {
  amount?: string;
  hideAmount?: boolean;
  hideHeader?: boolean;
  order?: CartItem[];
  disabled?: boolean;
  onCompleted?: (payment: any) => void;
};

export default function PaymentWidget(props: PaymentWidgetProps) {
  const { amount, hideAmount, hideHeader, order, onCompleted, disabled } =
    props;
  const navigateTo = useNavigate();
  const { createPayment, confirmOTP } = usePayments(config.apiEndpoint);
  const [processing, setProcessing] = useState(false);
  const [formState, setFormState] = useState(FormStates.InitialPayment);
  const [message, setMessage] = useState("");
  const [payResponse, setPayResponse] = useState<any>(undefined);

  const sendOtpHandler = useCallback(
    async (otp: string) => {
      try {
        await confirmOTP(otp, payResponse?.id, payResponse?.payId);
        setMessage("Payment successful");
        setFormState(FormStates.Success);
      } catch (err) {
        Logger.error(`Unable to confirm OTP. ${err}`, { config });
        setMessage("The payment failed. We were unable to confirm the OTP");
        setFormState(FormStates.Error);
      }
    },
    [setFormState, setMessage, confirmOTP, payResponse]
  );

  const initiatePayment = useCallback(
    async (data: any) => {
      setProcessing(true);
      try {
        const { amount, phoneNumber, provider } = data;
        const orderData = order
          ? {
              items: order.map((o) => ({
                ...o.item,
                quantity: o.quantity,
                selection: o.item?.selection?.filter((s) => s.quantity),
              })),
              customer: { phoneNumber },
            }
          : undefined;

        const results = await createPayment({
          description: `Payment from ${phoneNumber}`,
          amount,
          payment: {
            accountNumber: phoneNumber,
            provider,
          },
          order: orderData,
        });

        setProcessing(false);
        if (results.payStatus === SendOTPStatus) {
          setPayResponse(results);
          setFormState(FormStates.ConfirmOtp);
        } else if (results.payStatus === "Failed") {
          setMessage("The payment failed. Please contact support for help");
          setFormState(FormStates.Error);
        } else {
          setMessage("Payment successful");
          setFormState(FormStates.Success);
        }
      } catch (err) {
        Logger.error(`Unable to make payment. ${err}`, { data, config });
        setMessage("The payment failed. Please contact support for help");
        setFormState(FormStates.Error);
      }
    },
    [createPayment, setFormState, setMessage, setPayResponse, order]
  );

  const statusOkClickHandler = useCallback(() => {
    onCompleted && onCompleted(payResponse);
    navigateTo("/orders");
  }, [onCompleted, navigateTo, payResponse]);

  return (
    <FormContainer>
      {formState === FormStates.InitialPayment ? (
        <NewPaymentForm
          onSubmit={initiatePayment}
          loading={processing}
          amount={amount}
          hideAmount={hideAmount}
          hideHeader={hideHeader}
          disabled={disabled}
        />
      ) : null}

      {formState === FormStates.ConfirmOtp ? (
        <PaymentOtpForm onSendOtp={sendOtpHandler} processing={processing} />
      ) : null}

      {formState === FormStates.Success || formState === FormStates.Error ? (
        <StatusCard
          severity={formState === FormStates.Success ? "success" : "error"}
          primaryAction={{
            text: "OK",
            onClick: statusOkClickHandler,
          }}
          message={message}
        />
      ) : null}
    </FormContainer>
  );
}
