import { createBrowserRouter } from "react-router-dom";
import { ForgotPasswordPage, LoginPage } from "./authentication/pages";
import CreateOrderPage from "./admin/orders/newOrder";
import { ProductsPage } from "./admin/products";
import CreateProductPage from "./admin/products/create";
import EditProductPage from "./admin/products/edit";
import { AnalyticsPage } from "./admin/analytics";
import { OrderHistory } from "./admin/orders";
import ContactsPage from "./admin/contacts";

const router = createBrowserRouter([
  { path: "/", element: <LoginPage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/forgot-password", element: <ForgotPasswordPage /> },
  { path: "/dashboard", element: <OrderHistory /> },
  // { path: "/payments/new", element: <CreatePaymentPage /> },
  { path: "/orders/new", element: <CreateOrderPage /> },
  // { path: "/payments", element: <PaymentHistory /> },
  { path: "/orders", element: <OrderHistory /> },
  { path: "/products", element: <ProductsPage /> },
  { path: "/products/new", element: <CreateProductPage /> },
  { path: "/products/:productId/manage", element: <EditProductPage /> },
  { path: "/analytics", element: <AnalyticsPage /> },
  { path: "/contacts", element: <ContactsPage /> },
]);

export default router;
