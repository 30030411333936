import { useState, useCallback, useEffect } from "react";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { subscribe } from "../notifications";
import config from "../configLoader";
export const useSwUpdate = () => {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
    null
  );
  const [showReload, setShowReload] = useState(false);
  const onSWUpdate = useCallback((registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  }, []);

  const reloadPage = useCallback(() => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    window.location.reload();
    window.location.reload();
    window.location.reload();
  }, [waitingWorker]);

  const subscribeForPush = useCallback((swReg: ServiceWorkerRegistration) => {
    console.log("SW Registered: ", swReg);
    const token = subscribe(config.vapidPublicKey, swReg);
    console.log("Token: ", token);
  }, []);

  useEffect(() => {
    // serviceWorkerRegistration.register({
    //   onUpdate: onSWUpdate,
    //   onSuccess: subscribeForPush,
    // });
    serviceWorkerRegistration.unregister();
  }, [onSWUpdate, subscribeForPush]);
  return { showReload, waitingWorker, reloadPage };
};
