import { Stack } from "@mui/material";
import { MenuButton, MenuItemButton } from "../components/button";

export type MenuItemProps = {
  text: string;
  onClick: () => void;
  icon?: JSX.Element;
};
type MenuBarProps = {
  username: string;
  menuItems?: MenuItemProps[];
  userMenu?: MenuItemProps[];
};

export default function MenuBar(props: MenuBarProps) {
  const { menuItems, userMenu, username } = props;

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      border="gray 1px solid"
      borderRadius={4}
    >
      {menuItems?.map(({ text, onClick, icon }) => (
        <MenuItemButton variant="text" onClick={onClick} key={text}>
          {icon} {text}
        </MenuItemButton>
      ))}
      <MenuButton menuItems={userMenu}>{username ?? "You"}</MenuButton>
    </Stack>
  );
}
