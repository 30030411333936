import { CancelOutlined, CloudCircle, UploadFile } from "@mui/icons-material";
import {
  Typography,
  Stack,
  Button,
  ImageList,
  ImageListItem,
  Badge,
} from "@mui/material";
import { useState, useCallback, useMemo, useRef } from "react";

type ImageFieldProps = {
  label?: string;
  placeholder?: string;
  icon?: JSX.Element;
  max?: number;
  type?: "Image" | "Document";
  fileUrls?: string[];
  onFileChange?: (files: File[]) => void;
};

export function ImageField(props: ImageFieldProps) {
  const { label, icon, max, placeholder, fileUrls, onFileChange } = props;
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const uploadIcon = icon ?? <UploadFile color="info" />;
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  // Generate temporary src for selected files so we can preview
  const selectedFilesPreviewLinks = useMemo(
    () => selectedFiles.map((f) => URL.createObjectURL(f)),
    [selectedFiles]
  );

  // Merge fileUrls and previewLinks to get combined list for display
  const allImages = useMemo(
    () => [...(fileUrls ?? []), ...selectedFilesPreviewLinks].filter((x) => x),
    [fileUrls, selectedFilesPreviewLinks]
  );

  const selectFileClickHandler = useCallback(async () => {
    hiddenFileInput.current?.click();
  }, [hiddenFileInput]);

  const fileSelectedHandler = useCallback(
    (e: any) => {
      const newSelection = [...selectedFiles, ...e.target.files];
      setSelectedFiles(newSelection);
      if (typeof onFileChange === "function") {
        onFileChange(newSelection);
      }
    },
    [selectedFiles, setSelectedFiles, onFileChange]
  );

  const deleteImageHandler = useCallback(
    (img: string) => {
      if (img.startsWith("blob:")) {
        const loc = selectedFilesPreviewLinks.indexOf(img);
        if (loc < 0) return;
        const newSelectedList = [...selectedFiles];
        newSelectedList.splice(loc, 1);
        setSelectedFiles(newSelectedList);
        onFileChange && onFileChange(newSelectedList);
      }
    },
    [selectedFiles, selectedFilesPreviewLinks, onFileChange, setSelectedFiles]
  );

  return (
    <Stack border={1} borderRadius={1} borderColor="silver" mb={4}>
      <Stack direction="row" alignItems="center" padding={1}>
        {uploadIcon}
        <Typography fontWeight={400}>{label ?? "Upload file"}</Typography>
      </Stack>
      <Stack justifyContent="center" alignItems="center" padding={1}>
        {allImages && (
          <ImageList cols={max}>
            {allImages.map((img) => (
              <ImageListItem key={img}>
                {img.startsWith("blob:") && (
                  <Badge
                    style={{
                      position: "absolute",
                      top: 6,
                      right: 6,
                      cursor: "pointer",
                    }}
                  >
                    <span onClick={() => deleteImageHandler(img)}>
                      <CancelOutlined color="action" />
                    </span>
                  </Badge>
                )}
                <img
                  src={img}
                  srcSet={img}
                  loading="lazy"
                  style={{ maxWidth: 180, maxHeight: 180 }}
                  alt="Product display"
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}

        <Button
          size="large"
          onClick={selectFileClickHandler}
          disabled={(max || 1) <= allImages?.length}
        >
          <CloudCircle fontSize="large" />
        </Button>
        <Typography fontSize={10}>
          {placeholder ?? "Click to select file"}
        </Typography>
        <input
          type="file"
          hidden={true}
          ref={hiddenFileInput}
          onChange={fileSelectedHandler}
        />
      </Stack>
    </Stack>
  );
}
