import {
  Button,
  Card,
  Chip,
  CircularProgress,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import AdminLayout from "../layout";
import Spacing from "../../components/space";
import Currency from "../../components/currency";
import { PeopleAltOutlined, Phone } from "@mui/icons-material";
import useContacts from "../../hooks/useContacts";
import { socialDate } from "../../common/utils";
import { useMemo } from "react";
import config from "../../configLoader";

const ListContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: "#ededed",
  padding: "16px",
  width: "90%",
  [theme.breakpoints.up("lg")]: {
    width: "30%",
  },
  alignItems: "center",
  overflowX: "scroll",
  height: "100%",
}));

const StyledPaymentCard = styled(Card)`
  width: 100%;
  margin: 6px;
  padding: 12px;
  overflow: initial;
`;

export default function ContactsPage() {
  return (
    <AdminLayout>
      <Stack
        width="100%"
        alignItems="center"
        maxHeight="90vh"
        height="100%"
        overflow="scroll"
      >
        <Spacing />
        <Typography variant="h4">
          <PeopleAltOutlined /> Contacts
        </Typography>
        <Spacing />
        <ContactsList />
      </Stack>
    </AdminLayout>
  );
}

function contactRaised(contact: any) {
  let total = 0;
  const history = contact.history ?? {};
  for (const key in history) {
    if (history[key].amount) {
      total += Number(history[key].amount);
    }
  }
  return total;
}

const recentPaymentDate = (contact: any) => {
  const history = contact.history ?? {};
  const dates = Object.keys(history).map((key) => new Date(history[key].date));

  if (dates.length === 0) {
    return "";
  }
  return dates.reduce((a, b) => (a > b ? a : b)).toISOString();
};

function enrichContacts(contacts: any[]) {
  console.log("enrichContacts Contacts", contacts);
  if (!contacts) {
    return [];
  }
  return contacts
    .map((contact) => {
      const totalPayents = contactRaised(contact);
      const recentPayment = recentPaymentDate(contact);
      return {
        ...contact,
        totalPayents,
        recentPayment,
      };
    })
    .sort((a, b) => {
      const dateA = new Date(a.recentPayment);
      const dateB = new Date(b.recentPayment);
      return dateA > dateB ? -1 : 1;
    })
    .reverse();
}

function ContactsList() {
  const { contacts, processing, canLoadMore, loadMore } = useContacts(
    config.apiEndpoint
  );

  const enrichedContacts = useMemo(() => enrichContacts(contacts), [contacts]);

  if (processing) {
    return <CircularProgress />;
  }

  if (!contacts?.length) {
    return (
      <ListContainer>
        <Typography color="gray">No orders yet</Typography>
      </ListContainer>
    );
  }

  return (
    <ListContainer>
      {enrichedContacts.map((contact) => {
        const totalPayents = contact.totalPayents;
        const recentPayment = contact.recentPayment;
        return (
          <StyledPaymentCard elevation={0} key={contact.id}>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="h6">
                  {contact.firstName} {contact.lastName}
                </Typography>

                <Typography fontSize={10} color="gray">
                  <Phone fontSize="small" sx={{ fontSize: 14 }} />{" "}
                  {contact.phoneNumber}
                </Typography>
              </Stack>
              <Stack justifyContent="center" alignItems="flex-end">
                <Stack direction="row" alignItems="center">
                  <Currency amount={totalPayents} />
                </Stack>
                <Typography fontSize={10} color="gray">
                  <Chip
                    variant="outlined"
                    size="small"
                    sx={{ fontSize: 12 }}
                    label={`Last payment: ${socialDate(recentPayment)}`}
                  />
                </Typography>
              </Stack>
            </Stack>
          </StyledPaymentCard>
        );
      })}
      <Spacing />
      {canLoadMore && (
        <Button variant="contained" color="primary" onClick={loadMore}>
          Load more
        </Button>
      )}
    </ListContainer>
  );
}
