import {
  Alert,
  Button,
  Card,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Modal,
  Paper,
  Stack,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import config from "../../configLoader";
import usePayments from "../../hooks/usePayments";
import AdminLayout from "../layout";
import Spacing from "../../components/space";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { socialDate } from "../../common/utils";
import { Payment } from "../products/types";
import Currency from "../../components/currency";
import { MenuButton } from "../../components/button";
import { ArrowDropDown, Phone } from "@mui/icons-material";

const OrderStack = {
  Processing: "Processing",
  History: "History",
};

const OrderActiveStatuses = ["New", "Processing", "Ready", "Delivered"];

export function OrderHistory() {
  const { processing, payments: orders } = usePayments(config.apiEndpoint);
  const [tab, setTab] = useState(OrderStack.Processing);
  const navigateTo = useNavigate();
  const [selectedOrder, setSelectedOrder] = useState<Payment>();

  const newOrderHandler = useCallback(() => {
    navigateTo("/orders/new");
  }, [navigateTo]);

  const onOrderClick = useCallback(
    (order: Payment) => {
      setSelectedOrder(order);
    },
    [setSelectedOrder]
  );

  const onOrderClose = useCallback(() => {
    setSelectedOrder(undefined);
  }, [setSelectedOrder]);

  const activeOrders = orders.filter((p) =>
    OrderActiveStatuses.includes(p.order?.status ?? "")
  );

  return (
    <AdminLayout>
      <Stack width="100%" alignItems="center" height="100%">
        <ListContainer style={{ background: "transparent" }}>
          <Stack direction="row" justifyContent="space-around" width="100%">
            <Button onClick={newOrderHandler} variant="contained">
              New Order
            </Button>
          </Stack>
        </ListContainer>
        <Spacing />
        <Tabs value={tab} onChange={(_, v) => setTab(v)}>
          <Tab label="Pending" value={OrderStack.Processing} />
          <Tab label="All" value={OrderStack.History} />
        </Tabs>
        {tab === OrderStack.Processing && (
          <OrderssList
            payments={activeOrders}
            processing={processing}
            onOrderClick={onOrderClick}
          />
        )}
        {tab === OrderStack.History && (
          <OrderssList
            payments={orders}
            processing={processing}
            onOrderClick={onOrderClick}
          />
        )}
      </Stack>
      {selectedOrder ? (
        <OrderDetail
          payment={selectedOrder}
          open={Boolean(selectedOrder)}
          onClose={onOrderClose}
        />
      ) : (
        <></>
      )}
    </AdminLayout>
  );
}

const ListContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: "#ededed",
  padding: "16px",
  width: "90%",
  [theme.breakpoints.up("md")]: {
    width: "30%",
  },
  alignItems: "center",
  overflowX: "scroll",
  height: "100%",
}));

const StyledPaymentCard = styled(Card)`
  width: 100%;
  margin: 6px;
  padding: 12px;
  overflow: initial;
`;

type OrdersListProps = {
  payments: any[];
  processing?: boolean;
  onOrderClick?: (order: Payment) => void;
};

function OrderssList(props: OrdersListProps) {
  const { payments, processing, onOrderClick } = props;

  const handleClick = useCallback(
    (order: Payment) => {
      onOrderClick && onOrderClick(order);
    },
    [onOrderClick]
  );

  if (processing) {
    return <CircularProgress />;
  }

  if (payments.length === 0) {
    return (
      <ListContainer>
        <Typography color="gray">No orders yet</Typography>
      </ListContainer>
    );
  }

  const paymentByPhone = payments.reduce((acc, p) => {
    const phone = p.order?.customer?.phoneNumber ?? p.payment.accountNumber;
    if (!acc[phone] && p.order?.customer?.firstName) {
      acc[phone] = p.order.customer;
    }
    return acc;
  }, {});

  console.log(paymentByPhone);

  return (
    <ListContainer>
      {payments.map((p) => {
        const customer = paymentByPhone[p.payment.accountNumber];
        const customerName = [
          customer?.firstName ?? "",
          customer?.lastName ?? "",
        ]
          .join(" ")
          .trim();

        return (
          <StyledPaymentCard
            elevation={0}
            key={p.id}
            onClick={() => handleClick(p)}
          >
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography variant="h6">
                  {customerName || p.payment.accountNumber}
                </Typography>
                {customerName && (
                  <Typography fontSize={10} color="gray">
                    <Phone fontSize="small" sx={{ fontSize: 14 }} />{" "}
                    {p.payment.accountNumber}
                  </Typography>
                )}
                <Typography fontSize={10} color="gray">
                  {socialDate(p.timestamp)}
                </Typography>
              </Stack>
              <Stack justifyContent="center" alignItems="flex-end">
                <Stack direction="row" alignItems="center">
                  <Currency amount={p.amount} />
                </Stack>
                <Typography
                  fontSize={10}
                  color={p.payStatus === "Collected" ? "green" : "orangered"}
                >
                  <Chip
                    variant="outlined"
                    size="small"
                    label={
                      p.payStatus === "Collected"
                        ? p.order?.status ?? "Paid"
                        : "Not paid"
                    }
                    color={p.payStatus === "Collected" ? "success" : "warning"}
                  />
                </Typography>
              </Stack>
            </Stack>
          </StyledPaymentCard>
        );
      })}
    </ListContainer>
  );
}

type OrderDetailProps = {
  payment?: Payment;
  open?: boolean;
  onClose?: () => void;
};

const UpdateStatus = {
  initial: "initial",
  success: "success",
  error: "error",
};

function OrderDetail(props: OrderDetailProps) {
  const { payment, open, onClose } = props;
  const { updatePayment, processing } = usePayments(config.apiEndpoint);
  const [updateStatus, setUpdateStatus] = useState(UpdateStatus.initial);

  const handleStatusChange = useCallback(
    async (status: string) => {
      if (!payment || !payment.id) return;
      try {
        await updatePayment(payment.id, { order: { status } });
        setUpdateStatus(UpdateStatus.success);
      } catch (e) {
        console.log(e);
        setUpdateStatus(UpdateStatus.error);
      }
    },
    [payment, updatePayment]
  );

  const statusOptions = useMemo(
    () => [
      {
        text: "Processing",
        onClick: () => {
          handleStatusChange("Processing");
        },
      },
      {
        text: "Ready",
        onClick: () => {
          handleStatusChange("Ready");
        },
      },
      {
        text: "Delivered",
        onClick: () => {
          handleStatusChange("Delivered");
        },
      },
      {
        text: "Cancelled",
        onClick: () => {
          handleStatusChange("Cancelled");
        },
      },
    ],
    [handleStatusChange]
  );

  if (!payment || !payment.order) {
    return <></>;
  }

  const { order } = payment;

  return (
    <Modal
      open={open ?? false}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container maxWidth="sm" style={{ marginTop: 16 }}>
        <Paper style={{ padding: 24 }}>
          <Stack>
            {order.customer?.phoneNumber && (
              <Datum label="Customer" value={order.customer?.phoneNumber} />
            )}
            <Datum label="Status" value={order.status ?? "New"} />
            <Stack direction="row" justifyContent="start">
              <Datum label="Amount" value={payment.amount} />
              {payment.payStatus && (
                <Chip
                  size="small"
                  variant="outlined"
                  label={
                    payment.payStatus === "Collected" ? "paid" : "Not paid"
                  }
                  color={
                    payment.payStatus === "Collected" ? "success" : "warning"
                  }
                  style={{ marginLeft: 8 }}
                />
              )}
            </Stack>

            <Typography variant="h6">Items</Typography>
            <TableContainer>
              <Table>
                {order.items.map((item) => (
                  <TableRow>
                    <TableCell>
                      {item.quantity > 1 ? item.quantity : ""} {item.name}
                      {item.selection?.length ? (
                        <ol type="i">
                          {item.selection.map((s) => (
                            <li>
                              <Datum
                                label={`${s.name} (${s.quantity})`}
                                value={Number(s.price).toFixed(2)}
                              />
                            </li>
                          ))}
                        </ol>
                      ) : null}
                    </TableCell>
                    <TableCell>{Number(item.price).toFixed(2)}</TableCell>
                  </TableRow>
                ))}
                <TableFooter>
                  <TableRow>
                    <TableCell align="right">
                      <Typography variant="h6">Total</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">
                        <Currency amount={payment.amount} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <Divider />
            <Stack direction="row" justifyContent="space-between">
              {payment.payStatus === "Collected" && (
                <>
                  <MenuButton
                    icon={<ArrowDropDown />}
                    menuItems={statusOptions}
                  >
                    {`Status: ${order.status ?? "New"}`}
                  </MenuButton>
                  {processing && <CircularProgress />}
                  {updateStatus === UpdateStatus.success && (
                    <Alert severity="success">Status updated!</Alert>
                  )}
                  {updateStatus === UpdateStatus.error && (
                    <Alert severity="error">Failed to update status</Alert>
                  )}
                </>
              )}
              <Button onClick={onClose} disabled={processing}>
                Close
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Container>
    </Modal>
  );
}

function Datum(props: { label: string; value: string | number }) {
  const { label, value } = props;
  return (
    <div style={{ marginBottom: 8 }}>
      <span style={{ fontWeight: 600 }}>{label}: </span>
      <span style={{ marginLeft: 8 }}>{value}</span>
    </div>
  );
}
