import { useCallback, useEffect, useState } from "react";
import { Logger } from "../../common/logger";
import useAuthentication from "../../authentication/useAuthentication";
import useHttp from "@fast-ninjas/hooks.use-http";
import { Product } from "./types";

export function useProducts(apiEndpoint: string) {
  const [products, setProducts] = useState<Product[]>([]);
  const { getAccessToken, user } = useAuthentication();
  const { post, get, loading, patch } = useHttp();

  const createAccessHeader = useCallback(
    async () => ({
      Authorization: `Bearer ${await getAccessToken()}`,
    }),
    [getAccessToken]
  );

  const fetchProducts = useCallback(async () => {
    const authHeader = await createAccessHeader();
    try {
      const result = await get(`${apiEndpoint}/api/products`, authHeader);
      setProducts(result);
      return result as Product[];
    } catch (err) {
      Logger.error("Unable to load products", user);
    }
    return [];
  }, [get, apiEndpoint, user, createAccessHeader]);

  const createProduct = useCallback(
    async (data: Product) => {
      const authHeader = await createAccessHeader();
      const response = await post(
        `${apiEndpoint}/api/products`,
        data,
        authHeader
      );
      if (!response.ok) throw response.statusText;
      const product = await response.json();
      setProducts([...products, product]);
      return product;
    },
    [createAccessHeader, post, apiEndpoint, products, setProducts]
  );

  const updateProduct = useCallback(
    async (productId: string, data: Product) => {
      const authHeader = await createAccessHeader();
      const response = await patch(
        `${apiEndpoint}/api/products/${productId}`,
        data,
        authHeader
      );
      if (!response.ok) throw response.statusText;
      const product = await response.json();
      return product;
    },
    [createAccessHeader, patch, apiEndpoint]
  );

  useEffect(() => {
    fetchProducts()
      .then((ps) => setProducts(ps))
      .catch(Logger.error);
  }, [fetchProducts]);

  return {
    createProduct,
    updateProduct,
    processing: loading,
    fetchProducts,
    products,
  };
}
