import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ProductOption } from "./types";
import { ListAlt } from "@mui/icons-material";
import { useCallback, useMemo, useState } from "react";
import Spacing from "../../components/space";
import { Validator } from "../../common/validation";

type ProductOptionsEditorProps = {
  options: ProductOption[];
  onChange?: (options: ProductOption[]) => void;
};

export default function ProductOptionsEditor(props: ProductOptionsEditorProps) {
  const { options, onChange } = props;

  const addNewItem = useCallback(() => {
    onChange && onChange([...options, { name: "", price: 0 }]);
  }, [options, onChange]);

  const optionChangedHandler = useCallback(
    (opt: ProductOption, index: number, remove?: boolean) => {
      const optionsCopy = [...options];
      if (remove) {
        optionsCopy.splice(index, 1);
      } else {
        optionsCopy[index] = opt;
      }
      onChange && onChange(optionsCopy);
    },
    [onChange, options]
  );

  const disableAddButton = useMemo(
    () => options.some((o) => !o.name && !o.price),
    [options]
  );

  return (
    <Stack>
      {options &&
        options.map((opt, idx) => (
          <OptionForm
            name={opt.name}
            price={opt.price}
            key={`${opt.name}-${idx}`}
            index={idx}
            onChange={optionChangedHandler}
          />
        ))}
      <Spacing />
      <Button disabled={disableAddButton} onClick={addNewItem}>
        Add New Item
      </Button>
    </Stack>
  );
}

type OptionFormProps = {
  name: string;
  price: number;
  index: number;
  disabled?: boolean;
  onChange?: (opt: ProductOption, index: number, remove?: boolean) => void;
};
function OptionForm(props: OptionFormProps) {
  const { name, price, disabled, onChange, index } = props;
  const [isDisabled] = useState(disabled);
  const [isDirty, setIsDirty] = useState(false);
  const [option, setOption] = useState<ProductOption>({
    name: name,
    price: price,
  });
  const [error, setError] = useState("");

  const optionChangedHandler = useCallback(
    (name: string, value: string) => {
      setOption({ ...option, [name]: value });
      setIsDirty(true);
    },
    [option, setOption, setIsDirty]
  );

  const saveClickHandler = useCallback(() => {
    if (!option.name) {
      setError("Name is required");
      return;
    }

    if (!Validator.numberBetween(option.price.toString(), undefined, 0)) {
      setError("Price must be a positive number");
      return;
    }

    setError("");

    onChange && onChange(option, index);
    setIsDirty(false);
  }, [option, onChange, setIsDirty, setError, index]);

  const removeItemHandler = useCallback(() => {
    onChange && onChange(option, index, true);
  }, [option, onChange, index]);

  return (
    <Box>
      {error && <span style={{ color: "red" }}>{error}</span>}
      <Stack direction="row" justifyContent="space-around">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          autoFocus
          id="optName"
          label={option.name ? "Description" : undefined}
          name="optName"
          autoComplete="off"
          value={option.name}
          size="medium"
          placeholder="Description"
          disabled={isDisabled}
          error={error.length > 0}
          onChange={(e) => optionChangedHandler("name", e.currentTarget.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ListAlt />
              </InputAdornment>
            ),
          }}
        />
        <Spacing variant="vertical" />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="price"
          label={option.price ? "price" : undefined}
          name="price"
          autoComplete="off"
          value={option.price}
          size="medium"
          type="number"
          inputMode="decimal"
          placeholder="0.00"
          disabled={isDisabled}
          error={error.length > 0}
          onChange={(e) => optionChangedHandler("price", e.currentTarget.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Typography fontSize={10}>GHS</Typography>
              </InputAdornment>
            ),
          }}
        />
        <Spacing variant="vertical" />
        {isDirty && <Button onClick={saveClickHandler}>Save</Button>}
        {!isDirty && <Button onClick={removeItemHandler}>Remove</Button>}
      </Stack>
    </Box>
  );
}
