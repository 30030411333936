import { Box, InputAdornment, Stack, TextField } from "@mui/material";
import AdminLayout from "../layout";
import { useProducts } from "../products/useProducts";
import config from "../../configLoader";
import { Product } from "../products/types";
import { useCallback, useMemo, useState } from "react";
import { Search } from "@mui/icons-material";
import useCart, { CartItem } from "../../hooks/useCart";
import { ProductDisplay } from "./ProductDisplay";
import { OrderTray } from "./OrderTray";
import CustomizeOrder from "./customize";

export default function CreateOrderPage() {
  const { products } = useProducts(config.apiEndpoint);

  return (
    <AdminLayout>
      <Box bgcolor="#ededed" p={2}>
        <ProductsDisplay products={products} />
      </Box>
    </AdminLayout>
  );
}

type ProductsDisplayProps = {
  products: Product[];
};

export function ProductsDisplay(props: ProductsDisplayProps) {
  const { products } = props;
  const [search, setSearch] = useState("");
  const {
    items,
    cost,
    addItem,
    updateItem,
    removeItem,
    emptyCart,
    calculateCost,
  } = useCart();
  const [isOrderTrayOpen, setIsOrderTrayOpen] = useState(false);
  const [tempSelection, setTempSelection] = useState<CartItem>(); // This stores a selected item temporarily so that it can be customized

  const searchResults = useMemo(
    () =>
      products.filter(
        (p) =>
          p.name.toLowerCase().includes(search.toLowerCase()) ||
          (p.options &&
            p.options.some((o) =>
              o.name.toLowerCase().includes(search.toLowerCase())
            ))
      ),
    [products, search]
  );

  const openOrderTray = useCallback(() => {
    setIsOrderTrayOpen(true);
  }, [setIsOrderTrayOpen]);

  const closeOrderTray = useCallback(() => {
    setIsOrderTrayOpen(false);
  }, [setIsOrderTrayOpen]);

  const addItemToTray = useCallback(
    (product: Product) => {
      const newItem = addItem(product, 1);
      if (product.options?.length) {
        setTempSelection(newItem);
      } else {
        openOrderTray();
      }
    },
    [addItem, openOrderTray]
  );

  const closeSelectionDialog = useCallback(
    () => setTempSelection(undefined),
    [setTempSelection]
  );

  const finishOrderSelection = useCallback(
    (item: CartItem) => {
      updateItem(item);
      setTempSelection(undefined);
      openOrderTray();
    },
    [updateItem, setTempSelection, openOrderTray]
  );

  return (
    <>
      <Box
        pl={5}
        width="100%"
        flex={1}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          id="search"
          name="search"
          autoComplete="off"
          value={search}
          size="small"
          placeholder="Search"
          style={{ width: "70%" }}
          onChange={(e) => setSearch(e.currentTarget.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />

        <OrderTray
          order={items}
          cost={cost}
          isOpen={isOrderTrayOpen}
          updateItem={updateItem}
          removeItem={removeItem}
          openTray={openOrderTray}
          closeTray={closeOrderTray}
          emptyTray={emptyCart}
          editCustomization={setTempSelection}
          itemCostCalculator={calculateCost}
        />
      </Box>
      <Stack direction="row" justifyContent="space-around" flexWrap="wrap">
        {searchResults.map((p) => (
          <ProductDisplay product={p} addItem={addItemToTray} key={p.id} />
        ))}
      </Stack>

      {tempSelection && (
        <CustomizeOrder
          item={tempSelection}
          isOpen={tempSelection !== undefined}
          onClose={closeSelectionDialog}
          onChange={finishOrderSelection}
        />
      )}
    </>
  );
}
