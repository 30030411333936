import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Product } from "./types";
import AdminLayout, { FormContainer } from "../layout";
import { ProductForm } from "./ProductForm";
import useS3Upload from "../../hooks/useS3Upload";
import config from "../../configLoader";
import { Logger } from "../../common/logger";
import useAuthentication from "../../authentication/useAuthentication";
import { useProducts } from "./useProducts";
import { FormStates } from "../../common/constants";
import { Alert } from "@mui/material";

export default function EditProductPage() {
  const [formState, setFormState] = useState(FormStates.Initial);
  const [product, setProduct] = useState<Product>();
  const { updateProduct, processing, products } = useProducts(
    config.apiEndpoint
  );
  const { uploadFile } = useS3Upload(`${config.apiEndpoint}/api/files`);
  const { user } = useAuthentication();
  const { state } = useLocation();
  const { productId } = useParams();
  const navigateTo = useNavigate();

  useEffect(() => {
    let p = state;
    if (products.length && productId) {
      p = products.find((x) => x.id === productId);
    }

    if (!processing && !p) {
      navigateTo("/products");
    }
    setProduct(p);
  }, [productId, state, processing, products, products.length, navigateTo]);

  const formSubmitHandler = useCallback(
    async (data: any) => {
      let imageUrl = product?.imageUrl;
      try {
        if (data.files?.length) {
          const { fileKey } = await uploadFile(
            data.files[0],
            `product/${user?.attributes["custom:accountId"]}`
          );

          imageUrl = fileKey;
        }

        if (!productId) throw new Error("Missing product Id");

        await updateProduct(productId, {
          name: data.productName,
          price: data.price,
          imageUrl: imageUrl || "",
          options: data.options || [],
        });
        setFormState(FormStates.Success);
      } catch (error) {
        Logger.error(error);
        setFormState(FormStates.Error);
      }
    },
    [
      uploadFile,
      updateProduct,
      user?.attributes,
      product?.imageUrl,
      productId,
      setFormState,
    ]
  );

  return (
    <AdminLayout>
      <FormContainer>
        {formState === FormStates.Success && (
          <Alert severity="success">
            Update successful! <Link to="/products">Go back</Link>
          </Alert>
        )}
        {formState === FormStates.Error && (
          <Alert severity="error">Update failed. Try again later</Alert>
        )}
        <ProductForm
          product={product}
          onSubmit={formSubmitHandler}
          loading={processing}
        />
      </FormContainer>
    </AdminLayout>
  );
}
