import { useEffect, useState } from "react";
import config from "../configLoader";
import useS3Upload from "../hooks/useS3Upload";
import { CSSProperties } from "@mui/material/styles/createMixins";

type S3ImageProps = {
  fileName: string;
  style?: CSSProperties | undefined;
  alt?: string;
  title?: string;
};

export function S3Image(props: S3ImageProps) {
  const { loadImage, loading } = useS3Upload(`${config.apiEndpoint}/api/files`);
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    loadImage(props.fileName).then(setImageUrl);
  }, [props.fileName, loadImage, setImageUrl]);

  if (loading) return <></>;

  return (
    <img
      src={imageUrl}
      style={props.style}
      alt={props.alt}
      title={props.title}
    />
  );
}
