import {
  Badge,
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Cancel, ShoppingBasketOutlined } from "@mui/icons-material";
import { S3Image } from "../../components/s3Image";
import { CartItem } from "../../hooks/useCart";
import { QuantityEditor } from "./QuantityEditor";
import { useCallback, useMemo } from "react";
import Spacing from "../../components/space";
import PaymentWidget from "../payments/paymentWidget";
import Currency from "../../components/currency";

export type OrderTrayProps = {
  order: CartItem[];
  cost: string;
  isOpen?: boolean;
  updateItem: (item: CartItem) => void;
  removeItem: (key: string) => void;
  emptyTray?: () => void;
  openTray?: () => void;
  closeTray?: () => void;
  editCustomization?: (item: CartItem) => void;
  itemCostCalculator?: (item: CartItem) => string;
};

export function OrderTray(props: OrderTrayProps) {
  const {
    order,
    cost,
    openTray,
    closeTray,
    removeItem,
    updateItem,
    isOpen,
    emptyTray,
    editCustomization,
    itemCostCalculator,
  } = props;

  const onQuantityChanged = useCallback(
    (p: CartItem, quantity: number) => {
      const newItem = { ...p, quantity };
      updateItem(newItem);
    },
    [updateItem]
  );

  const sortedOrder = useMemo(
    () =>
      order.sort((a, b) => {
        if (a.key < b.key) return -1;
        if (a.key > b.key) return 1;
        return 0;
      }),
    [order]
  );

  const editOrderItemHandler = useCallback(
    (e: any, item: CartItem) => {
      e.preventDefault();
      editCustomization && editCustomization(item);
      closeTray && closeTray();
    },
    [editCustomization, closeTray]
  );

  return (
    <Box>
      <Stack>
        <IconButton onClick={order.length ? openTray : undefined}>
          <Badge badgeContent={order.length ?? ""} color="error">
            <ShoppingBasketOutlined fontSize="large" />
          </Badge>
        </IconButton>
        <Typography mt={0}>
          <span style={{ fontSize: 9, color: "gray" }}>GHS</span>
          {cost}
        </Typography>
      </Stack>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={closeTray}
        style={{ width: "80%" }}
      >
        <Stack p={2} style={{ backgroundColor: "#ededed", height: "100%" }}>
          <Typography variant="h4">Order Basket</Typography>

          {sortedOrder.map((p) => {
            const selectedOptions = p.item.selection?.filter((x) => x.quantity);
            const itemCost =
              (itemCostCalculator && itemCostCalculator(p)) ?? "0";

            return (
              <Stack
                p={1}
                mb={2}
                key={p.key}
                direction="row"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 4,
                  width: "100%",
                }}
                alignItems="center"
                justifyContent="start"
              >
                <S3Image
                  fileName={p.item.imageFile}
                  style={{ maxHeight: 60, maxWidth: 60, marginRight: 8 }}
                />
                <Stack width="65%">
                  <Typography variant="h6">{p.item.name}</Typography>
                  {selectedOptions?.length ? (
                    <Stack direction="row">
                      <Typography color="gray">
                        ({selectedOptions.map((o) => o.name).join(", ")})
                        <a
                          href="https://"
                          onClick={(e) => editOrderItemHandler(e, p)}
                          style={{ marginLeft: 8 }}
                        >
                          Change
                        </a>
                      </Typography>
                    </Stack>
                  ) : null}
                </Stack>
                <Typography alignContent="flex-end" fontSize={10} color="grey">
                  Qty:
                </Typography>
                <Box mr={4}>
                  <QuantityEditor
                    value={p.quantity}
                    onChange={(val) => onQuantityChanged(p, val)}
                    maximum={25}
                  />
                </Box>
                <Currency amount={Number(itemCost)} />
                <IconButton onClick={() => removeItem(p.key)}>
                  <Cancel />
                </IconButton>
              </Stack>
            );
          })}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" width="70%">
              Order Total
            </Typography>
            <Currency amount={Number(cost)} variant="large" />
          </Stack>
          <Spacing />
          <Box p={4}>
            <PaymentWidget
              amount={cost}
              hideAmount={true}
              hideHeader={true}
              order={order}
              disabled={!order?.length}
              onCompleted={(_) => emptyTray && emptyTray()}
            />
          </Box>
          <Spacing />
          <Spacing />
          <Button
            variant="outlined"
            onClick={closeTray}
            style={{
              height: 60,
              width: "50%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Add Another
          </Button>
          <Spacing size={180} />
          <Button variant="text" onClick={emptyTray}>
            Delete Order
          </Button>
        </Stack>
      </Drawer>
    </Box>
  );
}
